import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const DefaultFooter = () => import(/* webpackChunkName: "default-footer" */ '@/layouts/DefaultFooter.vue')
const Default = () => import(/* webpackChunkName: "default" */ '@/layouts/Default.vue')

const routes = [
  {
    path: '',
    component: Default,
    props: true,
    children: [
      {
        path: '/app/public-information',
        name: 'Projects',
        components: {
          default: () => import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
          footer: DefaultFooter
        }
      },
      {
        path: '/app/public-information/detail/:uuid',
        name: 'ProjectDetail',
        components: {
          default: () => import(/* webpackChunkName: "project-detail" */ '../views/ProjectDetail.vue'),
          footer: DefaultFooter
        },
        props: true
      }
    ]
  }
  // {
  //   path: '/app/public-information',
  //   name: 'Home',
  //   component: Home
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
