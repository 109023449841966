import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'

import App from './App.vue'
import router from './router'

import VueMaterial from 'vue-material'
import 'vue-material/dist/theme/default.css'
import 'vue-material/dist/vue-material.min.css'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// import '@/assets/css/main.css'
import '@/assets/scss/main.scss'

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import VueLoading from 'vuejs-loading-plugin'

import VueFilterDateFormat from 'vue-filter-date-format'

import rate from 'vue-rate'

import LoadScript from 'vue-plugin-load-script'

// import * as Sentry from '@sentry/browser'
// import * as Integrations from '@sentry/integrations'

// import * as Constants from '@/store/constants'
import SimpleVueValidation from 'simple-vue-validator'

require('vue2-animate/dist/vue2-animate.min.css')

// Sentry.init({
//   environment: Constants.ENV,
//   dsn: 'http://a23aa4b6621f4df785e3f0a62137e264@str.telecom.pt:9000/8',
//   integrations: [new Integrations.Vue({ Vue, attachProps: true })]
// })

Vue.use(VueLoading)

Vue.use(VueSweetalert2)

Vue.use(BootstrapVue)

Vue.use(VueMaterial)

Vue.use(rate)

Vue.use(LoadScript)

Vue.use(SimpleVueValidation)

Vue.use(VueFilterDateFormat, {
  dayOfWeekNames: [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday',
    'Friday', 'Saturday'
  ],
  dayOfWeekNamesNamesShort: [
    'Su', 'Mo', 'Tu', 'We', 'Tr', 'Fr', 'Sa'
  ],
  monthNames: [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ],
  monthNamesShort: [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
})

Vue.config.productionTip = false

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render (h) {
      return h(App, {
        props: {
          // single-spa props are available on the "this" object. Forward them to your component as needed.
          // https://single-spa.js.org/docs/building-applications#lifecyle-props
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa
        }
      })
    },
    router
  }
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
